window.addEventListener('DOMContentLoaded', () => {
    const marquizElements = document.querySelectorAll('.marquiz')

    if (marquizElements.length === 0) {
        return
    }

    createMarquiz()

    marquizElements.forEach((marquizElement) => {
        marquizElement.addEventListener('click', async (e) => {
            e.preventDefault()
            const modalId = (e.target as HTMLElement).dataset.modalId

            if (!modalId) {
                return
            }

            if (window.Marquiz) {
                window.Marquiz.showModal(modalId)
            }
        })
    })

    const marquizCloseIcon = document.querySelector('.mobile-quiz__close')

    if (marquizCloseIcon) {
        const marquizBlock = document.querySelector('.mobile-quiz')
        marquizCloseIcon.addEventListener('click', () => {
            marquizBlock?.classList.remove('mobile-quiz--active')
        })
    }
});

const createMarquiz = () => {
    const options = {
        host: '//quiz.marquiz.ru',
        region: 'eu',
        autoOpen: false,
        autoOpenFreq: 'once',
        openOnExit: false,
        disableOnMobile: false
    }

    const scriptTag = document.createElement('script')
    scriptTag.async = true
    scriptTag.src = '//script.marquiz.ru/v2.js'

    scriptTag.onload = () => {
        window.Marquiz.init(options);

        const marquizElements = document.querySelectorAll<HTMLAnchorElement>('.marquiz')

        marquizElements.forEach((marquizElement) => {
            const marquizLink = marquizElement.dataset.link as string
            const modalId = marquizElement.dataset.modalId
            
            marquizElement.href = marquizElement.dataset.link as string

            window.requestIdleCallback(() => {
                const ourDomainRegex = /^(?:https?:\/\/)?(?:quiz\.express-office|quiz\.home24)\..*$/i

                if (marquizLink && ourDomainRegex.test(marquizLink)) {
                    const marquizUrl = `https://quiz.provider.marquiz.ru/v1/quizzes/findByProjectDomain?projectKey=${modalId}&projectDomain=quiz.express-office.ru`

                    fetch(marquizUrl)
                        .then((r) => r.json())
                        .then((data) => {
                            if (data?.id?.length) {
                                marquizElement.dataset.modalId = data.id
                            }
                        })
                }
            })
        })
    }

    document.head.appendChild(scriptTag)
}
