import getEnv, { EnvMode, EnvDomain } from '~/helpers/get-env'
let initInProgress = false

window.addEventListener('load', () => {
    window.addEventListener('mousemove', initThirdParty)
    window.addEventListener('scroll', initThirdParty)
    window.addEventListener('click', initThirdParty)
}, {once: true})

const initThirdParty = () => {
    if (initInProgress) return void 0

    initInProgress = true

    window.requestIdleCallback(() => {
        createRecaptcha()
        createDiginetica()
    }, {timeout: 2000})

    window.removeEventListener('mousemove', initThirdParty)
    window.removeEventListener('scroll', initThirdParty)
    window.removeEventListener('click', initThirdParty)
}

const createRecaptcha = () => {
    const scriptTag = document.createElement('script')

    scriptTag.async = true
    scriptTag.src = "https://www.google.com/recaptcha/api.js?render=6LfRlIkpAAAAAH3bqlbbW1FrE89Xtb0ho5TZNEPI"

    document.head.appendChild(scriptTag)
}

const createDiginetica = () => {
    const env = getEnv()

    if (env.mode !== EnvMode.PROD) return void 0

    const scriptTag = document.createElement('script')

    let src = '//cdn.diginetica.net/1954/client.js?ts=' + Date.now()
    if (env.domain === EnvDomain.HOME24) src = '//cdn.diginetica.net/2700/client.js'

    scriptTag.async = true
    scriptTag.src = src

    let rel = ["https://cdn.diginetica.net", "https://tracking.diginetica.net", "https://tracking-app.diginetica.net"]

    rel.forEach(function(el) {
        const optyLink = document.createElement('link')

        optyLink.rel = 'preconnect'
        optyLink.href = el

        document.head.appendChild(optyLink)
    })

    document.head.appendChild(scriptTag)
}
