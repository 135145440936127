import {isInView} from '~/helpers/is-in-view'
import {isIOS} from '~/client/vanilla-components/detect'
import debounce from "~/helpers/debounce";

if (isIOS) {
    const event = debounce(() => {
        const input = document.querySelector<HTMLInputElement>('input:focus, textarea:focus')

        if (input && !isInView(input, 100)) {
            input.blur()
        }
    }, 500)

    document.addEventListener('scroll', event)
}
