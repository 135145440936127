interface IFlake {
    [key: string]: any
}
export const initLetItSnow = function (selector: string) {
    const flakes: IFlake[] = []
    const canvas = document.querySelector<HTMLCanvasElement>(selector)
    const ctx = canvas?.getContext("2d")
    let mX = -100
    let mY = -100

    let flakeCount = NaN
    if (window.innerWidth < 999) {
        flakeCount = 125
    } else {
        flakeCount = 450
    }

    if (!canvas) return

    canvas.width = window.innerWidth
    canvas.height = window.innerHeight

    function snow() {
        if (!ctx || !canvas) return
        ctx.clearRect(0, 0, canvas.width, canvas.height)

        for (let i = 0; i < flakeCount; i++) {
            const flake = flakes[i]
            const x = mX
            const y = mY
            const minDist = 250
            const x2 = flake.x
            const y2 = flake.y

            const dist = Math.sqrt((x2 - x) * (x2 - x) + (y2 - y) * (y2 - y))
            // const dx = x2 - x
            // const dy = y2 - y

            if (dist < minDist) {
                const force = minDist / (dist * dist)
                const xcomp = (x - x2) / dist
                const ycomp = (y - y2) / dist
                const deltaV = force

                flake.velX -= deltaV * xcomp
                flake.velY -= deltaV * ycomp
            } else {
                flake.velX *= 0.98
                if (flake.velY <= flake.speed) {
                    flake.velY = flake.speed
                }
                flake.velX += Math.cos((flake.step += 0.05)) * flake.stepSize
            }

            ctx.fillStyle = "rgba(255,255,255," + flake.opacity + ")"
            flake.y += flake.velY
            flake.x += flake.velX

            if (flake.y >= canvas.height || flake.y <= 0) {
                reset(flake)
            }

            if (flake.x >= canvas.width || flake.x <= 0) {
                reset(flake)
            }

            ctx.beginPath()
            ctx.arc(flake.x, flake.y, flake.size, 0, Math.PI * 2)
            ctx.fill()
        }
        requestAnimationFrame(snow)
    }

    function reset(flake: IFlake) {
        if (!canvas) return
        flake.x = Math.floor(Math.random() * canvas.width)
        flake.y = 0
        flake.size = Math.random() * 3 + 2
        flake.speed = Math.random() * 1 + 0.5
        flake.velY = flake.speed
        flake.velX = 0
        flake.opacity = Math.random() * 0.5 + 0.3
    }

    function init() {
        if (!canvas) return
        for (let i = 0; i < flakeCount; i++) {
            const x = Math.floor(Math.random() * canvas.width)
            const y = Math.floor(Math.random() * canvas.height)
            const size = Math.random() * 3 + 4
            const speed = Math.random() * 1 + 0.5
            const opacity = Math.random() * 0.5 + 0.3

            flakes.push({
                speed: speed,
                velY: speed,
                velX: 0,
                x: x,
                y: y,
                size: size,
                stepSize: Math.random() / 160,
                step: 0,
                opacity: opacity,
            })
        }

        snow()
    }

    canvas.addEventListener("mousemove", function (e) {
        (mX = e.clientX), (mY = e.clientY)
    })

    window.addEventListener("resize", function () {
        canvas.width = window.innerWidth
        canvas.height = window.innerHeight
    })

    init()
}
