import { initLetItSnow } from './hny/snow'

// 0
// импортировать ./happy-new-year.scss в public_html/themes/home24overrided/_dev/client/assets/styles/styles.scss
// импортировать этот файл в public_html/themes/home24overrided/_dev/client/vanilla-components/_components.ts

// 1
// для EO
// заменить логотип
//
// Десктоп:
// <div class="flex gap-[0.5em]">
//     {include file="./ny-logo-eo.tpl"}
//     {include file="./ny-logo-eo-text.tpl"}
// </div>
//
// Моб:
// {include file="./ny-logo-eo.tpl"}


// 2
// для h24
// добавить перед лого
// {include file="./ny-logo-h24.tpl"}

// ну и смотрим этот коммит

class HNYLogo {
    public uid: string
    private root: SVGElement | null
    private elements: NodeListOf<Element> | undefined
    private name: string
    private time2hide: number
    private time2show: number

    constructor(container: Element, name: string) {
        this.root = container.querySelector<SVGElement>(`[data-id="${name}"]`)
        this.elements = this.root?.querySelectorAll('.hny-logo-line')
        this.name = name
        this.time2hide = this.root && this.root.dataset.hide ? Number(this.root.dataset.hide) : 3000
        this.time2show = this.root && this.root.dataset.show ? Number(this.root.dataset.hide) : 1000
        this.uid = `_${Math.random().toString(36).substring(2, 5 + 2)}`
    }


    get timeout() {
        if (this.elements?.length) {
            const base = this.elements.length > 15 ? 3500 : 2000
            return base / this.elements?.length
        }

        return NaN
    }

    private getTotalAnimationTime(term: number) {
        if (this.elements) {
            const stop = term - this.elements?.length * 100
            return stop + (this.elements?.length - 1) * this.timeout
        }

        return NaN
    }

    public show() {
        if (!this.root) return
        this.root.classList.add('is-open')
        this.elements?.forEach((element, key) => {
            setTimeout(() => element.classList.add('is-open'), key * this.timeout)
        })

        setTimeout(() => {
            this.hide()
            document.dispatchEvent(new Event(`show:${this.name}:${this.uid}`))
        }, this.getTotalAnimationTime(this.time2show))
    }

    public hide() {
        this.elements?.forEach((element, key) => {
            setTimeout(() => element.classList.remove('is-open'), key * this.timeout)
        })

        setTimeout(() => {
            if (this.root) this.root.classList.remove('is-open')
            document.dispatchEvent(new Event(`hide:${this.name}:${this.uid}`))
        }, this.getTotalAnimationTime(this.time2hide))
    }
}

const initExO = () => {
    const logoContainers = document.querySelectorAll('.hny-logo-container')
    if (logoContainers.length) {
        logoContainers.forEach((container) => {
            const logo = new HNYLogo(container, 'logo')
            const ball = new HNYLogo(container, 'ball')
            const gift = new HNYLogo(container, 'gift')
            const tree = new HNYLogo(container, 'tree')
            const snow = new HNYLogo(container, 'snow')
            const date = new HNYLogo(container, 'date')
            const fire = new HNYLogo(container, 'fire')

            logo.show()

            document.addEventListener(`hide:logo:${logo.uid}`, () => ball.show())
            document.addEventListener(`hide:ball:${ball.uid}`, () => gift.show())
            document.addEventListener(`hide:gift:${gift.uid}`, () => tree.show())
            document.addEventListener(`hide:tree:${tree.uid}`, () => snow.show())
            document.addEventListener(`hide:snow:${snow.uid}`, () => date.show())
            document.addEventListener(`hide:date:${date.uid}`, () => fire.show())
            document.addEventListener(`hide:fire:${fire.uid}`, () => logo.show())
        })
    }
}

initExO()

const initH24 = () => {
    const home24buttons = document.querySelectorAll('.hny-home-logo')
    if (!home24buttons.length) return

    let isActiveSnow = false
    home24buttons.forEach((button) => {
        button.addEventListener('click', () => {
            isActiveSnow = !isActiveSnow
            const snowContainers = document.querySelectorAll('.hny-snow-container')
            const action = isActiveSnow ? 'add' : 'remove'

            home24buttons.forEach((e) => e.classList[action]('is-active'))
            snowContainers.forEach((e) => e.classList[action]('is-active'))
        })
    })

    const bannerRoot = document.querySelector('.slider-banners--main') as HTMLElement
    bannerRoot?.querySelectorAll('.slider-banners__slide-picture-wrapper').forEach((element, key) => {
        const snowContainer = document.createElement('canvas')

        snowContainer.classList.add('hny-snow-container')
        snowContainer.setAttribute('id', `hny-snow-container-${key}`)
        element.appendChild(snowContainer)

        initLetItSnow(`#hny-snow-container-${key}`)
    })
}

initH24()
